.previous-question {
  filter: blur(2px);
  pointer-events: none;
}

.question-content {
  // font-size: 1.2em;

  & div.mstr-type-text {
    // font-size: 17px;
  }
}
