.notification-list {
    // padding: 15px;
    margin: 0 auto;
    flex: 1;
    overflow: auto;
    width: 100%;

    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #329de5;
    }

    .user-name {
        flex: 1;
        padding-left: 10px;
        font-size: 15px;
    }

    .notification-header {
        display: flex;
    }

    .user-review-comment {
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .from-now {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }

    .comment {
        padding: 5px 10px 10px 0px;
        font-size: 17px;
        // cursor: pointer;

        .user-mention {
            font-weight: bold;
            background: rgba(50, 157, 229, 0.2);
        }
    }
}
