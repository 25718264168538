.mstr-button {
  background: #57ccf2;
  border: 1px solid #57ccf2;
  color: white;
  max-width: 100%;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  display: flex;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
