.initiative-home-screen {
  flex: 1;
  padding: 10px 0px;
  .welcome-message {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    img {
      width: 150px;
    }
    .welcome-text {
      font-size: 2em;
    }
  }
  .initiative-list {
  }
}

.initiative-item {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // animation-duration: 4s;
  // transition-duration: 2s;
  .initiative-details {
    display: flex;
    align-items: center;
    flex: 1;
    .initiative-icon {
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;
    }
    .initiative-name {
      // font-size: 1.5em;
      padding: 0px 10px;
    }
  }
  .initiative-button {
    // font-size: 1.5em;
    border: 1px solid rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    padding: 4px 8px;
    text-align: center;
  }
}

.initiative-intro-container {
  flex: 1;
  padding: 10px 20px;
  overflow: auto;
  .initiative-intro {
    font-size: 1.7em;
    line-height: 1.7em;
  }
}

.more-less-button {
  font-size: 15px;
  color: #3f51b5;
  cursor: pointer;
  font-weight: bold;
  padding-top: 10px;
}

.ms-modal {
  position: fixed;
  height: calc(100vh - 65px);
  left: 0;
  background: #ececec;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background: white;
    max-width: 90%;
    padding: 40px;
    border-radius: 5px;
  }
}
