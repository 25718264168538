.dotseparator {
  font-size: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-around;
  width: 100px;
  margin: auto;
}

.dotseparator > .sepdots:nth-child(1) {
  color: #e91e63;
}

.dotseparator > .sepdots:nth-child(2) {
  color: #4caf50;
}

.dotseparator > .sepdots:nth-child(3) {
  color: #03a9f4;
}
