.initiatives-list {
    flex: 1;
    // padding: 10px;

    .heading {
        font-size: 15px;
        padding: 10px;
    }

    .chat-initiative-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;

        &:hover {
            background-color: aliceblue;
        }

        .name {
            font-size: 15px;
        }
        .new-conversation {
            cursor: pointer;
            border-radius: 5px;
            padding: 7px 15px;
            font-size: 14px;
            color: white; 
            background-color: #3182ff;
        }
    }
}