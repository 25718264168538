.login-screen-container {
  background-repeat: round;
  background-position: unset;
  background-size: cover;
  height: calc(100vh-80px);
}

.login-form-container {

}

@media (min-width: 640px) {
  .login-form-container {
      width: 28rem/* 384px */;
  }
}