.return-section-warning {
  background: aliceblue;
  padding: 10px;
  font-size: 1.6em;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 5px;

  .exclamation-mark {
    width: 2em;
    padding-right: 10px;
  }
}
