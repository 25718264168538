.conversation-list {
  flex: 1;
  .heading {
    padding: 10px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-conversation {
      cursor: pointer;
      border-radius: 5px;
      padding: 7px 15px;
      font-size: 14px;
      color: white;
      background-color: #3182ff;
    }
  }
  .conversation-list-item {
    // display: flex;
    // padding: 10px;
    // cursor: pointer;
    // align-items: flex-start;
    // width: 100%;

    &:hover {
      background-color: aliceblue;
    }

    // .details-left {
    //   .initials {
    //     height: 40px;
    //     width: 40px;
    //     border-radius: 50%;
    //     background: #673ab7;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     color: white;
    //     font-size: 14px;
    //   }
    // }

  }
}
