.answer-text {
  max-width: 100%;
  padding: 10px 15px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.7em;
  font-size: 1.5em;
  width: 100%;
  box-sizing: border-box;
  resize: none;

  &.YES {
    background: #009688 !important;
    border: 1px solid #009688 !important;
    color: white;
    border-radius: 5px;
  }

  &.NO {
    background: #e08881 !important;
    border: 1px solid #e08881 !important;
    color: white;
    border-radius: 5px;
  }
}
.freeTextLoader{
  display: flex;
  align-items: center;
}

.hide-loading-dots {
  .dot-elastic {
    opacity: 0;
  }
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e94236;
  color: #e94236;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4384f1;
  color: #4384f1;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #33a951;
  color: #33a951;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
