.mstr-answer {
  display: flex;
  // font-size: 17px;
  padding: 10px 10px;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #056EA2;
  justify-content: space-between;
  cursor: pointer;
  color: #056EA2;

  .answer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.YES {
    background: #009688 !important;
    border: 1px solid #009688 !important;
  }

  &.NO {
    background: #e08881 !important;
    border: 1px solid #e08881 !important;
  }

  &.CNBC {
    background: #d19338 !important;
    border: 1px solid #d19338 !important;
  }
  &.answer-selected {
    transition: all 0.3s;
    background: #2196f3;
    border: 1px solid #2196f3;
    color: white;
  }

  .answer-feedback-icon {
    font-size: 1.3em;
    border: 2px solid white;
    border-radius: 50%;
    padding: 6px;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@-webkit-keyframes spinloader {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
