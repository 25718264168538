.doc-preview-container {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  // z-index: 1;
  background: white;
  display: flex;
  flex-direction: column;

  .preview-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .close {
      background-color: #4848ff;
      color: white;
      padding: 7px 15px;
      font-size: 14px;
      border-radius: 5px;
    }
  }

  .react-pdf__Page {
    display: flex;
    justify-content: center;
  }

  .preview-navigation {
    display: flex;
    padding: 15px;
    justify-content: center;
    font-size: 16px;
    align-items: center;

    .arrow {
      padding: 4px 9px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 7px 2px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
