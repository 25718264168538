.refdoc-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 14px;
}

.closing-sequence {
  padding-bottom: 20px;
}

.feedback-icons {
  display: flex;
  max-width: 300px;
  margin: auto;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100px;
  align-items: center;
}

.thumbs-icon {
  width: 50px;
  cursor: pointer;
}

.feedback-text {
  max-width: 100%;
  padding: 10px 15px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.7em;
  font-size: 1.5em;
  width: 100%;
  box-sizing: border-box;
  resize: none;
}
