.conversation-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  // cursor: pointer;

  &.floating {
    position: fixed;
    height: 550px;
    width: 450px;
    bottom: 0px;
    right: 20px;
    // border: 2px solid #fbfbfb;
    background: #f3f3f3;
    box-shadow: 0px 0px 12px 3px #00000045;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    transition: all 0.3s;

    &.collapsed {
      bottom: -490px;

      .minimise {
        align-items: flex-end !important;
      }
    }
  }

  .toolbar {
    height: 60px;
    display: flex;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
    color: white;

    font-size: 13px;

    .participants {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .participant-name {
        color: white !important;
      }
    }

    .toolbar-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      .toolbar-button {
        font-size: 20px;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        height: 30px;
        width: 30px;
        margin: 0px 0px 0px 7px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #629bf7;
        }
      }
    }
  }

  .heading {
    padding: 10px;
    font-size: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    // flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    .section-name {
      font-size: 12px;
      color: gray;
      padding-top: 5px;
    }
  }

  .participants {
    .participant {
      display: flex;
      align-items: center;
      .avatar {
        height: 35px;
        width: 35px;
        border-radius: 50%;
      }
      .details {
        padding: 0px 10px;

        .participant-name {
          font-size: 13px;
          color: gray;
        }
      }
    }
  }

  .messages {
    flex: 1;
    padding: 10px;
    overflow: auto;

    .separator-message {
      font-size: 12px;
      display: flex;
      padding: 35px 20px;
      color: gray;

      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin: auto;
        padding: 0px 10px;
      }
    }
  }

  .conversation-message {
    display: flex;
    align-items: flex-end;
    padding: 10px 5px;

    &.other-message {
      .message-container {
        background: #e9e9eb;
        color: black;
        position: relative;
        margin-left: 20px;

        &:before {
          content: "";
          width: 0px;
          height: 0px;
          position: absolute;
          border-left: 10px solid transparent;
          border-right: 5px solid #e9e9eb;
          border-top: 10px solid #e9e9eb;
          border-bottom: 7px solid transparent;
          left: -13px;
          bottom: 3px;
        }
      }
    }

    &.my-message {
      flex-direction: row-reverse;
      .message-container {
        position: relative;
        background: #1a8cfc;
        margin-right: 20px;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          // top: 50%;
          width: 0;
          height: 0;
          border: 15px solid transparent;
          border-left-color: #1a8cfc;
          border-right: 0;
          border-bottom: 0;
          margin-bottom: 0px;
          margin-right: -13px;
        }
      }
    }

    .message-container {
      margin: 0px 10px;
      font-size: 15px;
      color: white;
      padding: 8px 10px;
      border-radius: 7px;
      max-width: 70%;
      line-height: 20px;
    }
  }

  .send-container {
    display: flex;
    padding: 5px 5px 5px 10px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    // margin: 5px 15px 10px 15px;
    width: 100%;

    .typed-message {
      flex: 1;
      margin-right: 10px;
      font-size: 16px;
      outline: none;
      border: none;
      background-color: transparent;
    }

    .message-send-button {
      cursor: pointer;
      border-radius: 5px;
      padding: 7px 15px;
      font-size: 14px;
      color: white;
      background-color: #3182ff;

      &.disabled {
        background-color: #bbbbbb;
      }
    }
  }
}
