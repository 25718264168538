.document-buttons {
    display: flex;
    font-size: 16px;
    justify-content: space-around;
    padding: 15px 5px;
    color: #3F51B5;
    font-weight: bold;

    & > div {
        cursor: pointer;
    }
}