.section-questions-container {
  scroll-snap-align: end;
  scroll-snap-type: y proximity;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // height: 100vh;
  // padding: 0px 20px;
  flex: 1;
}

.question-container {
  padding-bottom: 20px;
}

.section-questions-container > div:last-child {
  scroll-snap-align: end;
  scroll-snap-type: y proximity;
}
