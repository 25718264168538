.SectionScreen {
  flex: 1;
  display: flex;

  .section-content-container {
    // padding: 0px 20px;
    // scroll-snap-align: end;
    // scroll-snap-type: y proximity;
    overflow: auto;
    padding-bottom: 0px;
    // height: 100vh;
    flex: 1;
  }

  .section-content-container.scroll-to-end {
    scroll-snap-align: end;
    scroll-snap-type: y proximity;
  }

  .section-content-container.scroll-to-end > div:last-child {
    scroll-snap-align: end;
    scroll-snap-type: y proximity;
  }

  .section-header {
    font-size: 2em;
    line-height: 1.4em;
    background: aliceblue;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .section-image {
    max-width: 100%;
    display: block;
    margin: auto;
  }

  .section-content {
    font-size: 1.7em;
    line-height: 1.7em;

    div {
      margin-top: 12px;
    }
  }

  .section-questions {
    font-size: 1.7em;
    line-height: 1.7em;
    display: flex;
    flex-direction: column-reverse;
  }

  .fade-section {
    filter: blur(2px);
    pointer-events: none;
  }

  .img {
    max-width: 100%;
  }
  a{
  text-decoration: inherit;
    color: #40a9ff !important;
  }
}

