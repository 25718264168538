@import 'antd/dist/antd.css';

body {
  margin: 0;
  background: rgba(56, 103, 215, 0.24);
  font-family: "Montserrat", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.App {
  font-size: 10px;
  font-family: "Montserrat", sans-serif !important;
  margin: auto;
  flex: 1;
  overflow: auto;
  // max-width: 600px;
  background: white;
  display: flex;
}

.mindstrength-crown-logo {
   height: 35px;
  //height: 20px;
  padding: 5px 0px;
}

.mstr-video {
  video {
    max-width: 100%;
  }
}

.mstr-image {
  text-align: center;
  img {
    max-width: 100%;
    width: 100%;
  }
}

.mentor-communication {
  // font-size: 17px;
  line-height: 1.8em;
}

.loader-container {
  width: 30px;
  padding-left: 10px;

  .loader-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px solid #f3f3f3;
    border-top: 3px solid transparent;
    animation: spinloader 1s linear infinite;
  }
}

.fade-content {
  filter: blur(2px);
  pointer-events: none;
}

.ant-drawer-body {
  display: flex;
  padding: 0px !important;
}

.user-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.user-initials {
  background: #03a9f4;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
ol li {
  margin-left: 17px;
  list-style-type: decimal;
}
ul li {
  margin-left: 17px;
  list-style-type: disc;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert !important;
  font-weight: revert !important;
}
.sharemodal .ant-modal-content .ant-modal-body {
  padding: 0 !important;

}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}